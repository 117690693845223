import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";

const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
      <div className='flex justify-center'>
        {/* <p className='text-black text-[1.7rem] font-semibold'>Reviews</p> */}
      </div>

      <Slider {...settings} className='dragging' >

      <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    Over an extended period, I discovered myself trapped in the clutches of a fraudulent crypto trading platform. I 
                    entrusted my earned capital, envisioning substantial profits. My savings grew to $100,000, but optimism turned to 
                    disappointment as phantom gains filled my account. My frustration intensified when attempting a withdrawal validated 
                    my suspicions—withdrawal was an impossible endeavor.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt- pl-5 pt-[20%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://qph.cf2.quoracdn.net/main-qimg-9279dfdafac9ae522b9779d0c5ce3a4f.webp" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4 '>
                            <p className="font-semibold">Forbes</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    In an unexpected turn, I was ensnared by a romantic deception woven by an imposter posing as a military officer. 
                    Convinced to lend $80,000, I awaited a $120,000 return. Regrettably, the scammer disappeared, leaving me financially 
                    devastated and grappling with profound betrayal.
                    Amid my anguish, fortune favored me when I found James Miller, a beacon of hope during my darkest hours. Their rapid 
                    expertise not only recovered my funds but also revealed the deception. They restored my peace of mind, and I owe James 
                    Miller a great debt for rescuing me.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://images.squarespace-cdn.com/content/v1/5a85cb45bff2009b51ce9c99/1562806298327-QBJHPWSJ98F8H6OSI8VC/halo-laser-recovery.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Hannah</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 pb-4'>
                    I was in the process of recovering my losses through a supposed recovery firm, but they took a substantial amount of 
                    money from me while promising to retrieve my lost funds. Fortunately, my path crossed with a savior, James Miller Hack 
                    , who helped me reclaim my money from the fraudulent recovery firm and successfully restored my funds from the 
                    scammers. Jame's expertise turned the tables on those who had taken advantage of me, and I am immensely grateful for 
                    he's assistance in rectifying this situation.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://photos2.spareroom.co.uk/images/flatshare/listings/large/42/43/42432094.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">MacAllister</p>
                            <p className='font-semibold'>Canada</p>
                        </div>
                    </div> 
           </div>
        </div>


        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    In an unfortunate turn, I mistakenly shared my bank details online, resulting in a swift depletion of over $80,000 from my
                    account. As the situation worsened with the threat of potential loans, I came across James Miller's recommendation. His 
                    expertise proved invaluable - not only did he recover my funds but also brought the scammer to justice, turning the 
                    tide in my favor.  I am immensely grateful for Elias's expertise, which not only rectified the financial damage but
                     also brought justice to the situation.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://photos2.spareroom.co.uk/images/flatshare/listings/large/86/07/86073904.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Ethan</p>
                            <p className='font-semibold'>Canada</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    Caught in a distressing situation, I found myself blackmailed by someone exploiting my past hardships. This online 
                    extortionist threatened harm to me and my family, leveraging my vulnerabilities. For years, they extracted significant 
                    sums of money from me, leaving me trapped in a cycle of payment. However, a turning point arrived when I was introduced 
                    to James Miller.
                    James Miller's intervention proved pivotal; he not only put an end to the blackmail but also ensured that the perpetrator
                    faced legal consequences. With James's expertise, the tables turned, freeing me from the clutches of extortion and 
                    securing justice.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://www.parents.com/thmb/NAgy8Uey1W99FwXMoG8xgrOqbUM=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/550_shutterstock_155492024-bde40b1757574d76b40981f9aefd2cd8.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Myka</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    Ensnared by a romance scammer who exploited my emotions, I found myself sacrificing everything to fulfill his demands. 
                    I sold my properties and even my car, all in an attempt to please him. It was a heart-wrenching realization when I 
                    discovered he was fake.
                    A friend's recommendation introduced me to James Miller, a true savior. With expertise, he unveiled the scammer's 
                    identity, recovered my money, and restored my properties. James Miller's actions turned my life around, freeing me 
                    from deception. My gratitude towards him knows no bounds.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://qph.cf2.quoracdn.net/main-qimg-8922fd32d10d08bd8e4125d32cdc3a36" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Edith</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>
      
      </Slider>
    </div>
  );
}

export default Review;
