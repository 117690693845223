
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
        <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase text-center">Client Success Against Crypto Currency Scam</p>
                </div>
                
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Reviews</p>
                </motion.div>
      <div className='flex justify-center'>
                <div className='pb-3'>
                    <p>Swipe to Read More</p>
                </div>
      </div>

      <Slider {...settings} className='dragging' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 '>
                    James Miller's expertise truly turned my distress into relief. When my Instagram account got hacked and disabled, 
                    I was devastated. James not only recovered my account but also provided invaluable guidance on enhancing my online 
                    security. His quick response and strategic approach set him apart.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://as1.ftcdn.net/v2/jpg/02/94/03/52/1000_F_294035211_53sPDrzh55hylp8U6hFUcejRQwWVIcSE.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-3 pl-4 '>
                            <p className="font-semibold">Abigail Harrington</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller is a digital magician! My Twitter account was suddenly taken over and disabled. James's meticulous 
                    investigation and technical prowess helped me regain control and prevent future breaches. His dedication to 
                    rectifying the situation is beyond commendable.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://i.pinimg.com/474x/d3/d1/d6/d3d1d6506fafce1de8c1310751f6d055---years.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Magnus</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 pb-4'>
                    I can't thank James Miller enough for his outstanding assistance. My Facebook account was compromised and disabled, 
                    causing me immense distress. James not only restored my account but also educated me on precautionary measures. His 
                    professionalism and ethical approach are remarkable
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://i.pinimg.com/236x/75/97/14/759714cb581df3754866a4c5ba0dc575---years.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold"> Patel</p>
                            <p className='font-semibold'>UK</p>
                        </div>
                    </div> 
           </div>
        </div>


        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller's skills are truly exceptional. I lost access to my TikTok account due to unauthorized access 
                    and disabling. James's systematic approach and understanding of the platform's nuances led to swift recovery. 
                    His guidance in securing my account was invaluable.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuWFG-mg4_81nF6iqdzWoKUhWRAw6t6yHDB7-dznh2zt2-rEc8Q5ktQx9JhsbXqvQPob4&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Elias Dupont</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller is a digital savior! My Snapchat account was taken over and disabled, leaving me helpless. James's 
                    technical prowess and relentless pursuit restored my account, and his insights into cybersecurity were enlightening. 
                    He's a guardian against online threats.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://www.shutterstock.com/image-photo/portrait-woman-260nw-181675373.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>                                                                                                                                                                                                                                                                                                                
                            <p className="font-semibold">Arabella Montgomery </p>
                            <p className='font-semibold'>UK</p>
                        </div>
                    </div> 
           </div>
        </div>

       
      
      </Slider>
    </div>
  );
}

export default Review;

