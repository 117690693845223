import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
        <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase text-center">Client Success Against Fake Hackers</p>
                </div>
                
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Reviews</p>
                </motion.div>
      <div className='flex justify-center'>
                <div className='pb-3'>
                    <p>Swipe to Read More</p>
                </div>
      </div>

      <Slider {...settings} className='dragging' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 '>
                    James Miller is a true savior! After falling victim to a fake hacker scam, I was devastated and desperate to recover 
                    my funds so i met a fake recovery frim and they took $40,000 from me claiming to restore my money back. Thankfully, 
                    James stepped in with his expertise and dedication. He tirelessly pursued the scammers, traced 
                    the transactions, and managed to reclaim my money. His professionalism and commitment to justice are unmatched.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://pickledplum.com/wp-content/uploads/2016/09/short-hair.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-3 pl-4 '>
                            <p className="font-semibold">Emily W.</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    Words can't express my gratitude to James Miller. I was duped by a hacker promising services to restore my disabled
                    instagram  & Facebook account but ended 
                    up losing $18,000. James not only recovered my funds but also provided invaluable 
                    guidance on how to enhance my cybersecurity. His knowledge and unwavering support are commendable.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://images.unsplash.com/photo-1562612618-76db0faf7c40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8MzAlMjB5ZWFyJTIwb2xkJTIwbWFufGVufDB8fDB8fHww&w=1000&q=80" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Atticus</p>
                            <p className='font-semibold'>Canada</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 pb-4'>
                    I was hesitant to reach out after being scammed by a fake hacker, but James Miller's 
                    reputation reassured me. He lived up to every expectation. Not only did he recover my 
                    money efficiently, but he also educated me on the signs of cyber scams. James is a beacon 
                    of hope in the fight against fraudulent hackers
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://images.unsplash.com/photo-1615332327357-ce22631750a6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8MzUlMjB5ZWFyJTIwb2xkJTIwd29tYW58ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Meg</p>
                            <p className='font-semibold'>U.K</p>
                        </div>
                    </div> 
           </div>
        </div>


        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller is a lifesaver! After falling for a fake hacker's trap, After Scamming $10,000 worth of Gift Card from me
                     I thought I'd never see my money again. 
                    But James's thorough investigation and strategic approach not only led to the recovery of my funds but also 
                    gave me a sense of justice. He's a reliable ally in the battle against cybercrime.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://media.istockphoto.com/id/160409804/photo/portrait-of-an-adult-man.jpg?s=612x612&w=0&k=20&c=HcFtrZkbbu4FU_jl9Id3Uk_vQV5lexKhtR3QUw6QMz8=" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Matteo</p>
                            <p className='font-semibold'>Canada</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller is a true guardian of justice in the digital realm. I was left in shambles after 
                    a fake hacker swindled me of $56,000 and he keep on asking for more money. James's approach was not just about recovering 
                    the funds, but also about restoring my faith. His meticulous investigation and strategic prowess led 
                    to the return of what was rightfully mine. In a world fraught with cyber dangers, James stands as a 
                    beacon of hope and expertise.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://thumbs.dreamstime.com/b/handsome-years-old-man-glasses-outdoors-30858353.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Benjamin</p>
                            <p className='font-semibold'>Australia</p>
                        </div>
                    </div> 
           </div>
        </div>

       
      
      </Slider>
    </div>
  );
}

export default Review;
