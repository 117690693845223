import React from 'react';
import { FaPhoenixFramework } from "react-icons/fa";
import { motion } from 'framer-motion';
import Navbar from "./Navbar";
import { HiOutlineUser, HiOutlineMail, HiOutlineGlobeAlt, HiOutlinePencilAlt } from 'react-icons/hi';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Progress from "./hooks/Progress";
import Footer from "./hooks/Footer";

const Contact = () => {
    const [isLoading, setIsLoading] = useState(true)
    const {
        register,
        trigger,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (e) => {
        console.log("~ e", e);
        const isValid = await trigger();
        if (!isValid) {
          e.preventDefault();
        }
      };

      useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)
  
        },5000)
   }, [])
    
  return (
    <div>

{isLoading ? (
        
        <div className="flex justify-center h-screen bg-[#e5e8e8]">
        <Progress  />
      </div>
        
  
    
  ) : ( <div className=''>
    <>
         <section class="Contact">
    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-10 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.2, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                    <p className='text-white relative text-[1.3rem] font-bold pb-2 '>James Miller Hack</p>
                        <div className="pl-2 pt-1">
                            <FaPhoenixFramework className="text-white relative text-[1.5rem] font-bold "/>
                        </div>
                </motion.div>
                <Navbar/>
                </div>
   
            <div class="Crypto-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className="flex justify-center pt-[0%]">
                  <joshua className="flex gap-4 xl:hidden md:hidden">
                      {/* <p>Home</p>
                      <p>Service</p> */}
                  </joshua>
                </div>

                 <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='flex justify-center pt-10 pl-3 pr-1 xl:pl-0 pb-[10%]'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.7, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <p className='text-[30px] xl:text-[40px] font-semibold'>Contact Us</p>
                  </motion.div>
               </div>
               </div>
            </div>
    </section>
       <div className='bg-[#eeeded]'>
        <form action="https://formsubmit.co/97cc40ee40b44acfbe1d58ebd41311ba"
          onSubmit={onSubmit}
          method="POST">

        
        <div className="flex flex-col justify-center items-center w-full h-screen " >
      <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-md formx">
        {/* <h2 className="text-2xl font-bold mb-4"></h2> */}

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlineUser className="text-gray-500 mr-2" />
            <label htmlFor="name" className="font-medium text-gray-700">Name</label>
          </div>
          <input
            type="text"
            id="name"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#1eb2a6]"
            {...register("Name", {
              required: true,
              maxLength: 100,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-2">
                    {errors.email.type === "required" && "Your Name Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlineMail className="text-gray-500 mr-2" />
            <label htmlFor="email" className="font-medium text-gray-700">Email</label>
          </div>
          <input
            type="email"
            id="email"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#1eb2a6]"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Email Is Required."}
                    {errors.email.type === "pattern" && "Invalid email address."}
                  </p>
                  </div>
                    )}

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlineGlobeAlt className="text-gray-500 mr-2" />
            <label htmlFor="country" className="font-medium text-gray-700">Country</label>
          </div>
          <input
            type="text"
            id="country"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#1eb2a6]"
            {...register("Country", {
              required: true,
              maxLength: 2000,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Country Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}

        <select id="my-select"
      {...register("selection", {
        required: true,
        
      })} className='border border-gray-300 rounded pl-2 pr-5 py-2 w-full focus:outline-none focus:ring-2 text-black focus:border-[#1eb2a6] mb-5'>
        <option value="" className='text-[gray]'>Select Service</option>
        <option value="Restore Lost Crypto">Restore Lost Crypto</option>
        <option value="Romance Scam Recovery">Romance Scam Recovery</option>
        <option value="Bitcoin Recovery">Bitcoin Recovery</option>
        <option value="Wallet Tracking">Wallet Tracking</option>
        <option value="Spy">Spying</option>
        <option value="Social Media Hack">Social Media hack/Recovery</option>
        <option value="Icloud Unlock">Icloud Unlock</option>
        <option value="Account Activation">Account Activation</option>
        <option value="BlackMail/Extortion">BlackMail/Extortion</option>
        <option value="USDT Recovery/Hack">USDT Recovery/Hack</option>
        <option value="Whatsapp Hack/Spy">Whatsapp Hack/Spy</option>
        <option value="Gmail Recovery/Hack">Gmail Recovery/Hack</option>
        <option value="Password Recovery">Password Recovery</option>
        <option value="Background Check Removal">Background Check Removal</option>
        <option value="Tracking">Tracking</option>
        <option value="Phoen Unlock">Phoen Unlock</option>
        <option value="Narcissist Victims Support Health Line">Narcissist Victims Support Health Line</option>
      </select>

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlinePencilAlt className="text-gray-500 mr-2" />
            <label htmlFor="description" className="font-medium text-gray-700">Describe your situation</label>
          </div>
          <textarea
            id="description"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#1eb2a6]"
            rows={4}
            {...register("Message", {
              required: true,
              maxLength: 2000,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white ">
                    {errors.email.type === "required" && "Message is Required"}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}

        <button
          type="submit"
          className="bg-[#1eb2a6] text-white rounded-md py-2 px-4 hover:bg-[white] hover:text-black 
          hover:border border-[#1eb2a6] transition duration-300"
        >
          Submit
        </button>
      </div>
    </div>
    </form>
    </div>
   
    <Footer/>
    </>
    </div> )}
    </div>
  )
}

export default Contact