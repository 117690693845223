import React from 'react';
import { FaPhoenixFramework } from "react-icons/fa" ;// Import your custom CSS file

function CustomizedProgressBars({ style }) {
  return (
    
    <div className="custom-progress-container" style={style}>
      <div className="custom-progress">
        <div className="custom-icon">
          <FaPhoenixFramework size={40}/>
        </div>
        <div className="custom-loader"></div>
      </div>
    </div>
    
  );
}

export default CustomizedProgressBars;
