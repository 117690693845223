import React from 'react';
import Navbar from './Navbar';
import { motion } from "framer-motion";
import { FaPhoenixFramework } from "react-icons/fa";
import CircularProgress from "./hooks/CircularProgress";
import  { BsCheck2All } from  "react-icons/bs";
import { Link } from "react-router-dom";
import Footer from "./hooks/Footer";
import { useState, useEffect } from 'react';
import Progress from "./hooks/Progress";
import SocialReview from "./reviews/SocialReview";

const SocialMedia = () => {

  const [isLoading, setIsLoading] = useState(true)


  useEffect(()=> {
      setIsLoading(true)
      setTimeout(()=> {
         setIsLoading(false)

      },5000)
 }, [])
  return (
    <div>

{isLoading ? (
        
        <div className="flex justify-center h-screen bg-[#e5e8e8]">
        <Progress  />
      </div>
        
  
    
  ) : ( <div className=''>
    <>
    <section class="Social">
    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-10 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.2, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                    <p className='text-white relative text-[1.3rem] font-bold pb-2 '>James Miller Hack</p>
                        <div className="pl-2 pt-1">
                            <FaPhoenixFramework className="text-white relative text-[1.5rem] font-bold "/>
                        </div>
                </motion.div>
                <Navbar/>
                </div>
   
            <div class="Crypto-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className="flex justify-center pt-[0%]">
                  <joshua className="flex gap-4 xl:hidden md:hidden">
                      {/* <p>Home</p>
                      <p>Service</p> */}
                  </joshua>
                </div>

                 <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='flex justify-center pt-10 pl-3 pr-1 xl:pl-0 pb-[10%]'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.7, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <p className='text-[30px] xl:text-[40px] font-semibold'>Social Media Hack/Recovery</p>
                  </motion.div>
               </div>
               </div>
            </div>
    </section>

    <div className="bg-[#e5e8e8] h-fit">
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}> 
                                    Amidst the expansive digital domain, we emerge as restoration specialists adept at reclaiming elusive accounts. 
                                    Spanning Instagram, Facebook, TikTok, Twitter, and beyond, our unmatched prowess in account recovery sets us in a 
                                    league of our own. Irrespective of the intricate convolutions or multifaceted nuances of platforms, we navigate the 
                                    trajectory to retrieval seamlessly. Our proficient cohort comprehends the inner machinations of virtual realms, f
                                    ortified with sophisticated methodologies to swiftly reinstate your access and resurrect your virtual presence.
                                </motion.p> <br/> <br/>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                                Within our service purview, our commitment resonates in furnishing clients with the essential instruments to 
                                unearth veracity and find resolution. Leveraging advanced social media acumen and judicious methodologies, we 
                                surveil suspected dishonest partners or fraudsters. While some may perceive this as "hacking," we deem it 
                                essential to amass evidence judiciously and under proper sanction. Our quintessential objective lies in 
                                conferring tranquility by presenting tangible evidence in a professional and ethical manner.
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://wtop.com/wp-content/uploads/2018/10/1022_comancomputer.jpg" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bg-[#fefefe] h-fit">
           <motion.div className='flex justify-center pt-10'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <p className='text-center font-semibold text-[#1eb2a6]'>Solutions</p>
            </motion.div>

            <motion.div className='flex justify-center'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
            <p className="text-black font-bold text-[2.4rem] text-center ">
              Key Services
            </p>
            </motion.div>



            <div className="mx-auto max-w-7xl overflow-hidden">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-5 xl:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="social-medi.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">All Social Media<br/>Hack/Recovery</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="sim-card.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Sim Card<br/>Cloning</p>
                           
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="mobile-chat.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-Black font-bold text-[1.2rem]
                            pt-3">Text Message/iMessage<br/>Spy/Hack</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="gmail.png" alt="" className="!w-[80px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Gmail Hack/<br/>Recovery</p>
                        </div>

                    </motion.div>


                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="shiel.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-[black] font-bold text-[1.2rem]
                            pt-3">24Hrs<br/>Support</p>
                        </div>

                    </motion.div>

                    
                </div>
            </div>
            
           </div>
           <div className="bg-[#ededef] h-fit">
            <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6]">WAYS WE HELP</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Why Choose Us</p>
                </motion.div>





            <div className="mx-auto overflow-hidden  md:max-w-7xl pb-10">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">  
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                            <img src="https://images.unsplash.com/photo-1584438784894-089d6a62b8fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80" 
                            alt='' className='rounded-md '/>                        
                        </div>
                    </div>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-md right__border"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                         We stand as pioneers in the realm of social media recovery, offering unparalleled expertise and 
                        solutions to individuals seeking to regain control over their digital presence. Here's why you should choose us for 
                        your social media recovery needs:
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Profound Expertise:</span> With 
                                years of experience in the intricate landscape of social media platforms, we 
                                possess an in-depth understanding of their functionalities, security mechanisms, 
                                and potential vulnerabilities. Our seasoned experts are equipped to handle a 
                                wide array of recovery scenarios.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Comprehensive Platform Coverage:</span>  Whether 
                                it's Instagram, Facebook, Twitter, TikTok, or any other social platform, our 
                                proficiency extends across the spectrum. We are adept at navigating the complexities 
                                of various platforms to ensure successful recovery.
                            </p>
                        </motion.div>

                       

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>  Legal and Ethical Approach:</span>  We 
                                believe in upholding the law and ethical standards in all our endeavors. 
                                Our methods are compliant with legal regulations and respectful of the privacy 
                                of all parties involved, ensuring a transparent and accountable process.
                            </p>
                        </motion.div>

                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    
                        
                  </div>
                </div>
                <div className="mx-auto overflow-hidden  md:max-w-7xl pb-[10%]" id='Review'>
                        <SocialReview/>
                </div>
            </div>

            <section class="hero">
                <div class="hero-content">
                  <h2 className="text-center">Need our professional services?</h2>
                  <p className="text-center">Contact us now to get started!</p>
                  <Link to="/Contact" class="contact-btn">Contact Us</Link>
                </div>
            </section>
            

    <Footer/>
    
    
    </>
    </div> )}
    </div>
  )
}

export default SocialMedia;