import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
        <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase text-center">Client Success Against Romance Scam</p>
                </div>
                
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Reviews</p>
                </motion.div>
      <div className='flex justify-center'>
                <div className='pb-3'>
                    <p>Swipe to Read More</p>
                </div>
      </div>

      <Slider {...settings} className='dragging' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 '>
                    After falling victim to a heartless romance scam, I lost $90,000. James Miller was my savior.
                     With his expertise, he tirelessly pursued the culprits and successfully recovered every penny. His dedication 
                     and determination turned my despair into relief. I am forever grateful to James and his team for restoring my 
                     financial security.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://farm9.staticflickr.com/8205/8198004356_689e22102b_b.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-3 pl-4 '>
                            <p className="font-semibold">Elara Montgomery</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    I thought I had found love online, but it turned out to be a devastating romance scam. 
                    I not only lost $120,000 amount of money but also my trust. James Miller's 
                    intervention was a game-changer. His strategic approach led to the recovery of my funds, 
                    and he guided me through the emotional aftermath. James is not just a recovery expert; 
                    he's a guardian of hope.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://image1.masterfile.com/getImage/NjAwLTA2Nzg2ODUzZW4uMDAwMDAwMDA=ABul8M/600-06786853en_Masterfile.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Lachlan Sinclair</p>
                            <p className='font-semibold'>Canada</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 pb-4'>
                    Being scammed by someone I thought I knew shattered my world. James Miller's swift 
                    action not only exposed the deception but also helped me regain my finances. His unwavering 
                    support during the recovery process was remarkable. I can't thank James enough for giving me a 
                    second chance and ensuring justice prevailed."
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://cdn.cliqueinc.com/posts/285200/beauty-routine-age-35-285200-1580400238322-square.700x0c.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Seraphina Bennett</p>
                            <p className='font-semibold'>Australia</p>
                        </div>
                    </div> 
           </div>
        </div>


        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    Falling prey to a romance scam left me devastated, both emotionally and financially. Then came James Miller. 
                    His meticulous investigation and legal expertise not only uncovered the scam but also restored my lost funds. 
                    James turned my nightmare into a story of triumph. His dedication is unmatched, and I am truly indebted to him.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://qph.cf2.quoracdn.net/main-qimg-6e9251509ce15466f5378a823b686210-lq" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Percival Turner</p>
                            <p className='font-semibold'>U.K</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    I never imagined a romance scam would rob me of my savings and trust. Thankfully, James Miller stepped in. His 
                    strategic approach and determination brought justice to my case. With James on my side, I not only regained my 
                    money but also my confidence. He's a true advocate for those who have been wronged.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://hips.hearstapps.com/hmg-prod/images/gettyimages-694016643-1523641375.jpg?resize=480:*" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Isolde Montgomery</p>
                            <p className='font-semibold'>Australia</p>
                        </div>
                    </div> 
           </div>
        </div>

       
      
      </Slider>
    </div>
  );
}

export default Review;
