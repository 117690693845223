import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Blackmail from "./Components/Blackmail";
import RomanceScamRecovery from "./Components/RomanceScamRecovery";
import FakeHackRecovery from "./Components/FakeHackRecovery";
import CryptoRecovery from "./Components/CryptoRecovery";
import SocialMedia from "./Components/SocialMedia";
import DeviceUnlock from "./Components/DeviceUnlock";
import About from "./Components/About";
import Contact from "./Components/Contact";

function App() {
  return (
   <Routes>
    <Route path='/Navbar' element={<Navbar/>}/>
    <Route path='/' element={<Home/>}/>
    <Route path="/Blackmail" element={<Blackmail/>}/>
    <Route path='/RomanceScamRecovery' element={<RomanceScamRecovery/>}/>
    <Route path="/FakeHackRecovery" element={<FakeHackRecovery/>}/>
    <Route path="/CryptoRecovery" element={<CryptoRecovery/>}/>
    <Route path="/SocialMedia" element={<SocialMedia/>}/>
    <Route path="/DeviceUnlock" element={<DeviceUnlock/>}/>
    <Route path="/About" element={<About/>}/>
    <Route path="/Contact" element={<Contact/>}/>
   </Routes>
  );
}

export default App;
