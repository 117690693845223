import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
        <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase text-center">Client Success Against Disabled And Hacked 
                    Social media Account</p>
                </div>
                
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Reviews</p>
                </motion.div>
      <div className='flex justify-center'>
                <div className='pb-3'>
                    <p>Swipe to Read More</p>
                </div>
      </div>

      <Slider {...settings} className='dragging' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 '>
                    In my quest to invest my pensions wisely and generate more income, I made an unfortunate decision to invest $75,000, 
                    my entire pension, in a platform promising returns of $119,000. Falling victim to a scam was devastating. However, 
                    my luck changed when I came across recommendations for James Miller online. His expertise and team not only restored 
                    my funds but also dismantled the fake platform. My gratitude to James Miller knows no bounds for bringing justice to 
                    the digital world.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHvoMnKEXoMV8jsN7IzYSa8AkZh9doAiFmGA&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-3 pl-4 '>
                            <p className="font-semibold">Aiden</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    I found myself in a grave predicament when I unknowingly shared my 12-word phrase with an online scammer, 
                    who swiftly drained my Trust Wallet account of $200,000 worth of Bitcoin. The weight of the loss led me to 
                    the brink of despair, but a ray of hope emerged when I confided in James Miller. Remarkably, in under a week, 
                    he orchestrated the retrieval of my funds. It's difficult to express the depth of my gratitude – James Miller 
                    gave me a second chance at life, and I am forever indebted to him.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://media.istockphoto.com/id/1284873957/photo/happy-to-have-a-place-i-can-call-home.jpg?s=612x612&w=0&k=20&c=eAzoPyvWYU19oxME47GOgVW_Kwm5QBfhn5pbKPPvsSI=" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Alex G.</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 pb-4'>
                    I was scammed $67,000 worth of bitcoin by a romance scammer, and he was also threatening to blackmail me and 
                    leak my secrets if I don't give him more money. I was tired of paying him in bitcoin because it's way too high 
                    for me. However, a friend recommended me to James Miller Hack, who helped me restore my stolen bitcoin, kept my 
                    secret safe, and restored my peace of mind.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://us.123rf.com/450wm/dubova/dubova1209/dubova120900008/15019451-face-portrait-d-une-belle-femme-de-35-ans.jpg?ver=6" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Isabella</p>
                            <p className='font-semibold'>Brazil</p>
                        </div>
                    </div> 
           </div>
        </div>


        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    For months, I have been investing in this fake platform called Quick Crypto Invest. I've been diligently saving 
                    for months, and my balance had reached over £122,000+. I hoped for my money to grow, unaware that the balance 
                    displayed on the website was entirely fabricated. I thought I had lost it all, but my perception changed when 
                    I came across recommendations about James Miller. He aided me in restoring my funds, dismantling the fraudulent 
                    platform. We truly need more individuals like James Miller in this world.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://i.pinimg.com/originals/d9/d6/e4/d9d6e40dd3959af46f05e7873de88382.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Benedicta</p>
                            <p className='font-semibold'>U.K</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    As someone who values online security and financial prudence, I found myself ensnared in a distressing situation. 
                    Despite my caution, I fell victim to a cunning online scam that resulted in a loss of $15,000 from my savings. The 
                    experience was not only financially taxing but also emotionally draining.
                    Fortunately, I stumbled upon James Miller's expertise while searching for solutions. James not only assisted in 
                    recovering my funds but also provided crucial guidance on bolstering my online defenses. His dedication and 
                    unwavering commitment to rectifying such situations are truly commendable.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://media.istockphoto.com/id/635850530/photo/mid-adult-businesswoman-smiling-towards-the-camera.jpg?s=612x612&w=0&k=20&c=zVlu9Msc1Ndl8ufAJtSeY0zrNXu_eTuD5-o_AyLUkuI=" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Elena Martinez</p>
                            <p className='font-semibold'>Spain</p>
                        </div>
                    </div> 
           </div>
        </div>

       
      
      </Slider>
    </div>
  );
}

export default Review;