import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link } from "react-router-dom";


export default function MediaCard() {
  return (
    <Card sx={{ maxWidth: 500 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="https://techcrunch.com/wp-content/uploads/2019/01/1-fcc-seal.jpg?w=1390&crop=1"
        title="green iguana"
      />
      <CardContent>
        <p className="font-bold ">
        FCC officially declares AI-voiced robocalls illegal
        </p>
        <div className="pt-3">
            <p>
            Devin Coldewey • JAN 17, 2024• <span className="text-[#1eb2a6]">News</span>
            </p>
        </div>
        <div className="pt-5">
        The FCC’s war on robocalls has gained a new weapon in its arsenal with the declaration of AI-generated voices as “artificial” and therefore definitely against the law when used in automated calling scams. It may not stop the flood of fake Joe Bidens that will almost certainly trouble our phones this election season, but it won’t hurt, either.
        </div>
      </CardContent>
      <div className="pl-5 pb-5">
            <Link to="/ArticlesX"><p className="text-[#1eb2a6] font-bold">
                {/* Continue Reading... */}
            </p>
            </Link>
        </div>
    </Card>
  );
}

