import React from 'react';
import Navbar from './Navbar';
import { motion } from "framer-motion";
import { FaPhoenixFramework } from "react-icons/fa";
import CircularProgress from "./hooks/CircularProgress";
import  { BsCheck2All } from  "react-icons/bs";
import { Link } from "react-router-dom";
import Footer from "./hooks/Footer";
import { useState, useEffect } from 'react';
import Progress from "./hooks/Progress";
import CryptoReview from "./reviews/CryptoReview";

const CryptoRecovery = () => {

  const [isLoading, setIsLoading] = useState(true)


  useEffect(()=> {
      setIsLoading(true)
      setTimeout(()=> {
         setIsLoading(false)

      },5000)
 }, [])
  return (
    <div>

{isLoading ? (
        
        <div className="flex justify-center h-screen bg-[#e5e8e8]">
        <Progress  />
      </div>
        
  
    
  ) : ( <div className=''>
    <>
    <section class="Crypto">
    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-10 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.2, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                    <p className='text-white relative text-[1.3rem] font-bold pb-2 '>James Miller Hack</p>
                        <div className="pl-2 pt-1">
                            <FaPhoenixFramework className="text-white relative text-[1.5rem] font-bold "/>
                        </div>
                </motion.div>
                <Navbar/>
                </div>
   
            <div class="Crypto-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className="flex justify-center pt-[0%]">
                  <joshua className="flex gap-4 xl:hidden md:hidden">
                      {/* <p>Home</p>
                      <p>Service</p> */}
                  </joshua>
                </div>

                 <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='flex justify-center pt-10 pl-3 pr-1 xl:pl-0 pb-[10%]'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.7, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <p className='text-[30px] xl:text-[40px] font-semibold'>Crypto Currency Recovery</p>
                  </motion.div>
               </div>
               </div>
            </div>
    </section>

    <div className="bg-[#e5e8e8] h-fit">
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}> 
                                    The James Miller Project is an innovative collaborative intelligence and asset tracking service that is 
                                    wholeheartedly devoted to aiding victims of cybercrimes and corporations in reclaiming their vanished or 
                                    pilfered assets. Our team of skilled specialists, led by James Miller, is armed with state-of-the-art 
                                    asset tracking and recovery technology to engage with victims of cybercrimes, comprehend their distinct 
                                    circumstances, and furnish them with the essential support to facilitate the retrieval of their assets.
                                </motion.p> <br/> <br/>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                                We empathize with the profound impact of cybercrimes on individuals and corporations, and our pledge, under the 
                                leadership of James Miller, is to furnish a dependable and effective service that will alleviate the burden and 
                                anguish of asset loss. Our team of experts, guided by James Miller, labors ceaselessly to ensure that each victim 
                                receives the indispensable aid required to recover their assets. Leveraging our cutting-edge asset tracing and 
                                recovery technology, led by James Miller, we can discern and trail stolen or lost assets, no matter how intricate 
                                the circumstances may be. Our team, under the guidance of James Miller, is thoroughly equipped to tackle any asset 
                                recovery scenario, and our commitment, overseen by James Miller, is to deliver a service that surpasses all 
                                expectations.
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://i.guim.co.uk/img/media/02a13db646bf56f56267498fc0fff61888b52787/0_228_5184_3110/master/5184.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=547812baba10369c2bde8b4c9015644b" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bg-[#fefefe] h-fit">
           <motion.div className='flex justify-center pt-10'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <p className='text-center font-semibold text-[#1eb2a6]'>Solutions</p>
            </motion.div>

            <motion.div className='flex justify-center'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
            <p className="text-black font-bold text-[2.4rem] text-center ">
              Key Services
            </p>
            </motion.div>



            <div className="mx-auto max-w-7xl overflow-hidden">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.1, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                          <img src="cryptocurrencies.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">All Cryptocurrencies 
                             <br/>Recovery</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="profit.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Fake Broker Site<br/>Investment  Recovery</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="mobile-banking.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Fake Bank<br/>Investment Recovery</p>
                           
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="wallet.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-Black font-bold text-[1.2rem]
                            pt-3">Hacked Crypto<br/>Wallet Recovery</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="asset.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Fake Bitcoin<br/>Investment Recovery</p>
                        </div>

                    </motion.div>


                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="shiel.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-[black] font-bold text-[1.2rem]
                            pt-3">24Hrs<br/>Support</p>
                        </div>

                    </motion.div>

                    
                </div>
            </div>
            
           </div>
           <div className="bg-[#ededef] h-fit">
            <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6]">WAYS WE HELP</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Why Choose Us</p>
                </motion.div>





            <div className="mx-auto overflow-hidden  md:max-w-7xl pb-10">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">  
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                            <img src="https://www.investopedia.com/thmb/VN9z3hJL8V_l3dwTOgTK1_HYMtk=/2121x1414/filters:no_upscale():max_bytes(150000):strip_icc()/INV_Crypto_FOMO_GettyImages-1308419971-f41ca43688ec4cefb8253580ef16cf13.jpg" 
                            alt='' className='rounded-md'/>                        
                        </div>
                    </div>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-md right__border"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        We understand the distress and financial turmoil caused by fake hackers. Our dedicated team is 
                        committed to helping you reclaim your hard-earned money and restore your peace of mind. Here's why you should choose us 
                        for fake hackers recovery:
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'> Expertise in Cybersecurity:</span> Our 
                                seasoned professionals possess a wealth of knowledge in cybersecurity, digital forensics, and 
                                financial tracing. We leverage cutting-edge techniques to track down fraudulent hackers and
                                 recover your funds.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Track Record of Success:</span>  Over 
                                the years, we've successfully assisted numerous clients in recovering their 
                                lost funds from fake hackers. Our proven track record speaks to our commitment 
                                and effectiveness in this field.
                            </p>
                        </motion.div>

                       

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'> Customer-Centric Approach:</span>  Your 
                                satisfaction is our priority. We put your needs first and strive to provide 
                                a seamless and hassle-free experience from the initial consultation to the 
                                successful recovery of your funds.
                            </p>
                        </motion.div>

                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    
                        
                  </div>
                </div>
                <div className="mx-auto overflow-hidden  md:max-w-7xl pb-[10%]" id='Review'>
                        <CryptoReview/>
                </div>
            </div>

            <section class="hero">
                <div class="hero-content">
                  <h2 className="text-center">Need our professional services?</h2>
                  <p className="text-center">Contact us now to get started!</p>
                  <Link to="/Contact" class="contact-btn">Contact Us</Link>
                </div>
            </section>
            

    <Footer/>
    
    
    </>
    </div> )}
    </div>
  )
}

export default CryptoRecovery;