import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
        <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase text-center">Client Success Against Blackmail</p>
                </div>
                
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Reviews</p>
                </motion.div>
      <div className='flex justify-center'>
                <div className='pb-3'>
                    <p>Swipe to Read More</p>
                </div>
      </div>

      <Slider {...settings} className='dragging' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 '>
                    James Miller's expertise was a lifeline when my family faced a blackmail attempt involving private photos. As 
                    a father of two from Ontario, I needed to safeguard our privacy and end the threat.
                    James's guidance and careful investigation led to swift action, ensuring the safety of my family's privacy. 
                    His legal support proved invaluable in putting an end to the ordeal.
                    I highly recommend James's services to anyone in a similar situation. His dedication and strategic approach
                     are unmatched in dealing with cyber threats and blackmail.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://iran-tc.com/wp-content/uploads/2020/08/%D8%B4%D8%A7%D9%BE%D8%B1%DA%A9.png" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-3 pl-4 '>
                            <p className="font-semibold">Robert Mitchell</p>
                            <p className='font-semibold'> Ontario Canada</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    As a young professional from the UK targeted by a cyber blackmailer, James Miller's intervention was my saving grace. 
                    Not only did he expose the fraudster's identity, but he also helped me regain control over my personal and professional 
                    life.
                    Our collaboration showcased the potential of expertise and determination to thwart deceitful schemes. James's
                     guidance empowered me to confront the challenge head-on, ultimately turning the tables on the blackmailer.
                     I'm immensely thankful for James's support in restoring my security and confidence.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://media.sciencephoto.com/image/c0309894/800wm" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Emma Harrison,</p>
                            <p className='font-semibold'>United Kingdom</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 pb-4'>
                    As an Australian university student confronted with compromising photos being exploited against me, James Miller's 
                    swift action and legal assistance were a lifeline. His intervention empowered me to reclaim control over my narrative, 
                    proving that the images were shared without consent.
                    James's dedication led to a safer online presence for me, allowing me to regain a sense of security. His support
                     throughout the process was invaluable in restoring my peace of mind and asserting my rights.
                     I'm grateful for James's expertise in turning a distressing situation into an opportunity for resolution and empowerment.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl8jFLKULZzDOaRgvfejuv_Xjx9NzVF9mpRP_2POYndL4DsWZHAioaTCUTpW7tje9AGrc&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Davies</p>
                            <p className='font-semibold'>Australia</p>
                        </div>
                    </div> 
           </div>
        </div>


        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    Navigating a distressing situation where a blackmailer threatened to expose my personal history was daunting for 
                    me as a single mother from the USA. James Miller's guidance became my beacon of hope, empowering me to protect my 
                    family's reputation and security.
                    With James's expertise, I transformed fear into triumph and showcased the potential to overcome adversity. 
                    Together, we asserted our rights and turned the tables on the threat.
                    James's support was a pivotal factor in my journey from vulnerability to empowerment. His commitment to 
                    safeguarding my family's dignity and freedom was truly remarkable.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://static3.machteamsoft.ro/thumbnails_med/profile/537/13767537_4546938416crop_1672.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Nora White</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller's intervention came as a lifeline when my family faced a crippling blackmail situation. 
                    Having paid a substantial amount to the blackmailer, our resources were depleted. With James's expertise, 
                    the blackmailer was swiftly dealt with, and our money was successfully recovered.
                    James's support not only freed us from the threat but also restored our financial stability. His dedication 
                    to justice and resourcefulness in handling the situation were truly invaluable. We are immensely grateful for 
                    his intervention during our challenging time.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL7Kmri1RB0ZHjMddMGTUAuxMZyeyYwLlvrw&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Johnson</p>
                            <p className='font-semibold'>Canada</p>
                        </div>
                    </div> 
           </div>
        </div>

       
      
      </Slider>
    </div>
  );
}

export default Review;
