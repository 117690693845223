
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
        <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase text-center">Client Success Against Locked Devices</p>
                </div>
                
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Reviews</p>
                </motion.div>
      <div className='flex justify-center'>
                <div className='pb-3'>
                    <p>Swipe to Read More</p>
                </div>
      </div>

      <Slider {...settings} className='dragging' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 '>
                    I was locked out of my smartphone after forgetting the password, and I was in a panic. Then I 
                    contacted James Miller for help. His professionalism and quick response were impressive. In no time, he 
                    unlocked my device without any loss of data. James's expertise saved the day, and I'm truly grateful.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSoIL_J8K9U8ssdDarBei5ZdLgJcaVacdQmaRN0dH0ZP_nXdixvPJMi9V0jnOGM3lJ9ZQ&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-3 pl-4 '>
                            <p className="font-semibold">Sarah M</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller is a device unlocking maestro! My tablet had been locked due to a factory reset gone wrong. 
                    I was apprehensive about losing important data. James not only unlocked the device but also retrieved the 
                    data I thought was lost forever. His skills are unmatched.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://photos2.spareroom.co.uk/images/flatshare/listings/large/76/43/76433755.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Stefan</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5 pb-4'>
                    I had accidentally set a PIN on my smartwatch and forgot it. James Miller's expertise came to the rescue. His 
                    efficient and secure unlocking process not only regained my access but also ensured my device's safety. James's 
                    professionalism and dedication are second to none.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://photos2.spareroom.co.uk/images/flatshare/listings/large/53/39/53399869.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Kai</p>
                            <p className='font-semibold'>UK</p>
                        </div>
                    </div> 
           </div>
        </div>


        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    Being locked out of my device was incredibly frustrating. I reached out to James Miller, and his 
                    proficiency was evident from the start. He unlocked my device swiftly and explained the process 
                    in a way that was easy to understand. James's service is a game-changer!
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://photos.spareroom.com/images/flatshare/listings/large/20/69/206989232.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>
                            <p className="font-semibold">Alex Madona</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

        <div className='slide-wrapper'>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2">
                <div className='pl-5 pr-5'>
                    <p className='pt-5'>
                    James Miller's device unlocking prowess is unparalleled. My smartphone was locked due to a software glitch. James's 
                    technical acumen and methodical approach not only unlocked my device but also ensured it was free from any lingering 
                    issues. I couldn't have asked for a better solution.
                    </p>
                </div>
                
               
                    <div className="bg-[reen] h-fit pt-5 pl-5">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://photos.spareroom.com/images/flatshare/listings/large/20/72/207266064.jpg" alt=""/>
                            </Avatar>
                            </div>
                        
                           
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            <BsStarFill className="text-[#1eb2a6]"/>
                            
                        </div>
                        <div className='relative left-12 bottom-7 pl-4'>                                                                                                                                                                                                                                                                                                                
                            <p className="font-semibold">Carlos Mendez</p>
                            <p className='font-semibold'>USA</p>
                        </div>
                    </div> 
           </div>
        </div>

       
      
      </Slider>
    </div>
  );
}

export default Review;

