import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { FaPhoenixFramework } from "react-icons/fa";
import { useRef, useEffect } from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import { Avatar } from '@mui/material';
import CircularProgress from "./hooks/CircularProgress";
import { BsCheck2All } from "react-icons/bs";
import Review from "./hooks/Reviews";
import Card from "./hooks/Crad";
import Cards from "./hooks/Crads";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Footer   from  "./hooks/Footer";
import {  FaInstagram, FaEnvelope } from 'react-icons/fa';
import { BiLogoTelegram } from "react-icons/bi";

const Home = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };
  return (
   <>
   <div className="box1 overflow-hidden">
            <section class="herox">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <div>
                    <div className="flex space-x-4 pt-10 xl:ml-0 ml-10 relative ">
                        <a href="https://instagram.com/jamesmillerhackk?igshid=NTc4MTIwNjQ2YQ== " className="text-white hover:text-gray-500">
                            <FaInstagram size={20} color='white'/>
                        </a>
                        <a href="mailto:Jamesmillerhackk@gmail.com " className="text-white hover:text-gray-500">
                            <FaEnvelope size={20} />
                        </a>

                        <a href="https://t.me/jamesmillerhack" className="text-white hover:text-gray-500">
                            <BiLogoTelegram size={20} />
                        </a>
                    </div>
                    </div>
                <motion.div className='pt-10 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                    <p className='text-white relative text-[1.3rem] font-bold pb-2 '>James Miller Hack</p>
                        <div className="pl-2 pt-1">
                            <FaPhoenixFramework className="text-[#1eb2a6] xl:text-[#1eb2a6] relative text-[1.5rem] font-bold "/>
                        </div>
                </motion.div>
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>Your Trusted Recovery Partner!</p>
                                </motion.div>

                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Expert Team & Advanced <br/>Technology For Efficient  Retrieval</p>
                                </motion.div>

                                <motion.div className='pt-2'
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>
                                    Specializing in adept recovery services for individuals and businesses alike, 
                                    we guarantee efficient and seamless asset retrieval.
                                    </p>
                                </motion.div>

                                <div className='bg-[reen] h-20 flex gap-1 mr-1 mt-5'>
                                  <a href="https://jamesmillerhackk.com/signup/" target='_blank' rel="noreferrer">
                                    <button className='get__one'>Sign-Up<i class="ri-arrow-right-line"></i></button>
                                  </a>
                                    <Link to="/Contact"><button className='get__two'>Report Fraud <i class="ri-arrow-right-line"></i></button></Link>
                                </div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>



        <div className="bg-[#f7f7f8] h-fit">
            <div className="mx-auto overflow-hidden  md:max-w-7xl">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                    <div className="bg-[reen] h-fit hidden md:lock xl:block">
                        <div>
                            <img src="about-1.jpg" alt="" className="h-[99vh]"/>
                        </div>
                    </div>
                    <div className="bg-[reen] h-fit">
                        <div className="bg-[ink] h-fit">
                            <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.2, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <p className="font-semibold text-[1.2rem] text-[#1eb2a6]">Our Ploicy</p>
                            </motion.div> 

                            <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <p className="font-bold text-[46px] ">
                                    <motion.span
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.6, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>Benefits Of</motion.span>  <br/> 
                                    <motion.span
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.8, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>Working With Us</motion.span> 
                                </p>
                            </motion.div>       
                        </div>
                        <motion.div className="bg-[white] h-fit mt-5 pl-5 pr-5 pt-3 pb-3 rounded-md"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.4 }}
                        transition={{ delay: 0.4, duration: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: { opacity: 1, x: 0 },
                        }}>
                            <div className="flex">
                                <div>
                                    <img src="refund.png" alt="" className="w-[64px]"/>
                                </div>
                                <div className="pl-5 pt-6">
                                    <p className="font-bold">
                                        Refud policy
                                    </p>
                                </div>
                            </div>

                            <div className="pt-1">
                                Leveraging a wide range of expertise within our grasp, we maintain strong confidence in our 
                                impressive success rate, reducing the chance of refund requests. However, if you're unsatisfied 
                                with our services, rest assured, as we offer a quick and straightforward refund process.
                            </div>
                        </motion.div>
                        <motion.div className="bg-[white] h-fit mt-5 pl-5 pr-5 pt-3 pb-3 rounded-md"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.4 }}
                        transition={{ delay: 0.6, duration: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: { opacity: 1, x: 0 },
                        }}>
                            <div className="flex">
                                <div>
                                    <img src="phoenix.png" alt="" className="w-[64px]"/>
                                </div>
                                <div className="pl-5 pt-6">
                                    <p className="font-bold">
                                    Resilience
                                    </p>
                                </div>
                            </div>

                            <div className="pt-1">
                            Within our recovery agency, we epitomize the fundamental trait of resilience. Our dedication to aiding 
                            clients in demanding situations remains resolute. With a comprehensive spectrum of skills, we uphold an 
                            exceptional achievement rate, reducing the necessity for reimbursements.
                            </div>
                        </motion.div>
                        <motion.div className="bg-[white] h-fit mt-5 pl-5 pr-5 pt-3 pb-3 rounded-md"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.4 }}
                        transition={{ delay: 0.8, duration: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: { opacity: 1, x: 0 },
                        }}>
                            <div className="flex">
                                <div>
                                    <img src="accuracy.png" alt="" className="w-[64px]"/>
                                </div>
                                <div className="pl-5 pt-6">
                                    <p className="font-bold">
                                    Accuracy
                                    </p>
                                </div>
                            </div>

                            <div className="pt-1">
                            Unearth the pure excellence of our incredibly adaptable and ever-changing group, overflowing with proficiency 
                            across a multitude of fields. Observe the effortless harmony as we unite to skillfully organize and achieve 
                            impeccable results, ensuring nothing falls short of perfection. influence
                            </div>
                        </motion.div>

                    </div>
                </div>
            </div>


            <section class="numbers">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      transition={{ delay: 0.2, duration: 0.5 }}              
                      variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                      }}>
                        <div className='flex'>
                          <div className="bg-[#1eb2a6] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="money-bagx.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                                $<AnimatedNumbers value={65}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      transition={{ delay: 0.4, duration: 0.5 }}              
                      variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                      }}>
                        <div className='flex'>
                          <div className="bg-[#1eb2a6] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="costumer.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={16000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      transition={{ delay: 0.6, duration: 0.5 }}              
                      variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                      }}>
                        <div className='flex'>
                          <div className="bg-[#1eb2a6] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="handshakex.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={13000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      transition={{ delay: 0.8, duration: 0.5 }}              
                      variants={{
                      hidden: { opacity: 0, y: 50 },
                      visible: { opacity: 1, y: 0 },
                      }}>
                        <div className='flex'>
                          <div className="bg-[#1eb2a6] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="shieldxx.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Protected Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
        </section>
      
        </div>


        <div className="bg-[#ededef] h-fit">
            <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6]">KEY SOLUTIONS</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Our Services</p>
                </motion.div>


            <div className="mx-auto overflow-hidden  md:max-w-7xl">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-[white] h-fit rounded-md"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[#1eb2a6] !w-[60px] !h-[60px]">
                                <img src="blackmailo.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                   Help From Blackmail/Extortion
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2 pb-3 pt-3">
                            <p>
                            Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. 
                            Our dedicated mission is to provide unwavering support and compassionate assistance 
                            to individuals who have unfortunately encountered these distressing experiences. We 
                            steadfastly stand by their side, offering expert guidance to those who have fallen prey 
                            to online threats and malicious intent.
                            </p>
                        </div>

                        <div className="flex justify-center pb-5 ml-5">
                            <Link to="/Blackmail" className="w-[90%]">
                                <button className="learn__x">Learn More</button>
                            </Link>
                        </div>

                    </motion.div>
                    <motion.div className="bg-[white] h-fit rounded-md"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[#1eb2a6] !w-[60px] !h-[60px]">
                                <img src="broken-heartx.png" alt="" className="!w-[70px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                   Romance Scam Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2 pb-3 pt-3">
                            <p>
                            Dedicated to Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress 
                            caused by these deceitful actions. With our focused experts, we're determined to rebuild your confidence and 
                            guide you through the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment.
                            we guide you in overcoming distress.
                            </p>
                        </div>

                        <div className="flex justify-center pb-5 ml-5">
                            <Link to="/RomanceScamRecovery" className="w-[90%]">
                                <button className="learn__x">Learn More</button>
                            </Link>
                        </div>
                    </motion.div>
                    <motion.div className="bg-[white] h-fit rounded-md"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[#1eb2a6] !w-[60px] !h-[60px]">
                                <img src="refundx.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-2 pt-4">
                                <p className="font-semibold ">
                                   Recovery Of Money from Fake hackers
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2 pb-3 pt-3">
                            <p>
                            Expertly Dedicated to Recovering Funds from Scammers, our specialized Team is steadfast in restoring your 
                            finances with relentless determination. Count on our experienced team to navigate the intricate process, 
                            ensuring the swift and secure return of your hard-earned money. Your peace of mind is our utmost priority.
                            </p>
                        </div>

                        <div className="flex justify-center pb-5 ml-5">
                            <Link to="/FakeHackRecovery" className="w-[90%]">
                                <button className="learn__x">Learn More</button>
                            </Link>
                        </div>
                    </motion.div>
                    <motion.div className="bg-[white] h-fit rounded-md"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.7, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[#1eb2a6] !w-[60px] !h-[60px]">
                                <img src="bitcoinxo.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                   Crypto Currency Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2 pb-3 pt-3">
                            <p>
                            Have you ever felt the frustration of lost funds or cryptocurrency slipping through your grasp? Worry not, 
                            for we're here to guide you through the intricate path of their recovery. Rest assured, with our expertise, 
                            you can regain access to your locked-out wallet. Your journey is made smoother with the adept assistance , 
                            led by James Miller and his dedicated team of professionals.
                            </p>
                        </div>

                        <div className="flex justify-center pb-5 ml-5">
                            <Link to="/CryptoRecovery" className="w-[90%]">
                                <button className="learn__x">Learn More</button>
                            </Link>
                        </div>
                    </motion.div>
                    <motion.div className="bg-[white] h-fit rounded-md"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.8, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[#1eb2a6] !w-[60px] !h-[60px]">
                                <img src="social-mediax0.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                   Social Media Hack/Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2 pb-3 pt-3">
                            <p>
                            Empowering Your Digital Connections: Social accounts act as crucial links to numerous achievements and cherished connections. 
                            Recognizing their importance, we stand unwavering in our dedication to surpass expectations, leaving no stone unturned in 
                            ensuring your swift account recovery. Trust in our unwavering expertise and commitment to promptly reinstate your access.
                            </p>
                        </div>

                        <div className="flex justify-center pb-5 ml-5">
                            <Link to="/SocialMedia" className="w-[90%]">
                                <button className="learn__x">Learn More</button>
                            </Link>
                        </div>
                    </motion.div>
                    <motion.div className="bg-[white] h-fit rounded-md"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.9, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[#1eb2a6] !w-[60px] !h-[60px]">
                                <img src="unlockedx.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                   Phone Hack/unlock
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2 pb-3 pt-3">
                            <p>
                            Elevate Your Expectations with James Miller Comprising a team of esteemed specialists in phone unlocking, 
                            rest assured, no detail escapes our scrutiny. Our meticulous expertise and unwavering commitment guarantee 
                            thoroughness in each phase, delivering definitive solutions tailored to your requirements. Place your trust in 
                            us for a seamless, hassle-free experience beyond compare.
                            </p>
                        </div>

                        
                        <div className="flex justify-center pb-5 ml-5">
                            <Link to="/DeviceUnlock" className="w-[90%]">
                                <button className="learn__x">Learn More</button>
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>

            <div className="bg-[#ededef] h-fit">
            <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6]">WAYS WE HELP</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Why Choose Us</p>
                </motion.div>





            <div className="mx-auto overflow-hidden  md:max-w-7xl">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">  
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                            <img src="https://st3.depositphotos.com/24359662/33149/i/450/depositphotos_331497884-stock-photo-programmer-working-on-computer-in.jpg" 
                            alt='' className='rounded-md'/>                        
                        </div>
                    </div>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-md right__border"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                            With James Miller at the helm and a dedicated team by your side, James Miller is your unwavering partner 
                            in regaining what's rightfully yours. Our commitment to excellence, paired with our team's profound grasp of recovery 
                            intricacies, sets us apart. Here's why opting for us is the optimal choice for you and your assets:
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Experience Matters:</span> With
                                 years of expertise, we're adept at navigating complex recovery scenarios.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Compassionate Support:</span> Our
                                 empathetic team stands by you, offering both expertise and understanding.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Proven Success:</span> Our track record showcases our ability to deliver tangible results.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Your Satisfaction Guaranteed:</span>  Your
                                peace of mind is our ultimate goal – choose us for a dedicated partner in recovery..
                            </p>
                        </motion.div>

                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    

                </div>
            </div>
            
            <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase">"Testimonies"</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Reviews</p>
                </motion.div>
        <div className="mx-auto overflow-hidden  md:max-w-7xl pt-10">
        
            <div className='flex justify-center'>
                <div className='pb-3'>
                    <p>Swipe to Read More</p>
                </div>
            </div>
            <Review/>
        </div>



        <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6] uppercase">NEWS</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Our Blogs</p>
                </motion.div>




                <div className="mx-auto overflow-hidden  md:max-w-7xl">
            
            <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                <div className="bg-[reen] h-fit">
                    <div className="flex justify-center">
                        <Cards/>
                   </div>
                </div>

                <div className="bg-[reen] h-fit">
                    <div className="flex justify-center">
                        <Card/>
                   </div>
                </div>

                
            </div>
        </div>


                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.7 }}
                        transition={{ delay: 0.2, duration: 0.7 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}
                        className="basis-1/2 mt-10 md:mt-0"
                    >
                    <div className="max-w-md mx-auto  overflow-hidden md:max-w-2xl mt-[10%]" id='FAQ'>

                    
                        <div className="flex justify-center">
                            <p className="font-semibold pt-4 text-[#1eb2a6]"> Frequently Ask Questions </p>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-[46px] font-semibold">
                               F .A. Q
                            </p>
                        </div>
                    </div>
                    </motion.div>

                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.7 }}
                        transition={{ delay: 0.2, duration: 0.7 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }} >

                    <div className="max-w-md mx-auto  overflow-hidden md:max-w-6xl mt-[5%] mb-[5%] pl-3 pr-2">
                        <div className=" grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5">
                            <div className="bg-[f60505] h-fit overflow-hidden">
                            <Accordion className="">
                                <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <p className="text-[#030d26] font-semibold">100% Refund Policy</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                We are dedicated to achieving top-notch outcomes and fulfilling our clients' anticipations. Although we are 
                                self-assured in our capacity to accomplish the assigned job, we acknowledge that unforeseen situations can 
                                occasionally emerge, potentially affecting our ability to fulfill our commitments. In circumstances where 
                                task completion becomes unfeasible, we extend a reimbursement as part of our pledge to secure our clients' 
                                contentment and establish reliance in our brand. We uphold principles of openness and impartiality in all 
                                interactions, and our terms and conditions are meticulously crafted to provide lucidity and safeguard the 
                                interests of both our patrons and our team. We are resolute in delivering extraordinary service and worth 
                                to our clients, consistently pursuing enhanced processes and exceeding anticipations.
                                </AccordionDetails>
                            </Accordion>    

                                <div className="pt-5">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>}
                                             aria-controls="panel1a-content"  id="panel1a-header" >
                                            <p className="text-[#030d26] font-semibold">Can I rely on you?</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        We recognize that factors such as credibility and expertise play a crucial role in requiring 
                                        meticulous consideration before our clients can truly feel secure and confident in handing over 
                                        their sensitive financial details. We completely understand that the choice to place trust in us 
                                        demands thoughtful scrutiny, and we take on this responsibility with the highest level of seriousness.
                                         We firmly uphold that trust is garnered through steadfast dedication and unwavering excellence. 
                                         We are consistently driven to exceed our clients' anticipations across all dimensions of our 
                                         services.
                                        </AccordionDetails>
                                    </Accordion>    
                                </div>
                            </div>

                            <div className="bg-[f60505] h-fit">
                                <div className="">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>} aria-controls="panel1a-content" id="panel1a-header">
                                            <p className="text-[#030d26] font-semibold">What is the process of recovery?</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        At James Miller's Recovery Services, we fully grasp the distinctiveness inherent in every account 
                                        recovery instance, necessitating an individualized approach. Our strategy encompasses an array of 
                                        distinct techniques specifically tailored to the unique attributes of each situation. <br/><br/>

                                        For password retrieval, we collaborate closely with our clients to extract encrypted private key 
                                        backups, alongside an exhaustive compilation of potential password guesses. Our state-of-the-art 
                                        algorithms and advanced software empower us to delve into billions, or even trillions, of potential 
                                        password combinations until the correct one is unveiled. We spare no effort to guarantee a triumphant 
                                        account recovery journey.
                                        </AccordionDetails>
                                    </Accordion>    
                                </div>

                                <div className="pt-5">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>}
                                             aria-controls="panel1a-content"  id="panel1a-header" >
                                            <p className="text-[#030d26] font-semibold">What is the typical duration of the process?</p>
                                        </AccordionSummary>
                                            <AccordionDetails>
                                            The Cryptocurrency recovery process timeline can vary, ranging from a mere hour to spanning 
                                            multiple months. Although this may seem like an extended duration, the initial week is dedicated 
                                            to conducting an exhaustive search upon client engagement. Following this, we retain encrypted 
                                            private backups and password guesses, facilitating ongoing testing to explore novel recovery 
                                            pathways and bolster our hardware capacities. We place significant emphasis on safeguarding 
                                            client confidentiality and security, with the deletion of client data only occurring upon 
                                            explicit request.
                                           </AccordionDetails>
                                    </Accordion>    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    </motion.div>
                    <section class="hero ">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>

                    <Footer/>

      </div>

           
  
   </>
  )
}

export default Home