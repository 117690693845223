import React from 'react';
import Navbar from './Navbar';
import { motion } from "framer-motion";
import { FaPhoenixFramework } from "react-icons/fa";
import CircularProgress from "./hooks/CircularProgress";
import  { BsCheck2All } from  "react-icons/bs";
import { Link } from "react-router-dom";
import Footer from "./hooks/Footer";
import { useState, useEffect } from 'react';
import Progress from "./hooks/Progress";
import BlackmailReview from "./hooks/BlackmailReview";

const Blackmail = () => {
  const [isLoading, setIsLoading] = useState(true)


  useEffect(()=> {
      setIsLoading(true)
      setTimeout(()=> {
         setIsLoading(false)

      },5000)
 }, [])
  return (
    <div>

{isLoading ? (
        
        <div className="flex justify-center h-screen bg-[#e5e8e8]">
        <Progress  />
      </div>
        
  
    
  ) : ( <div className=''>
    <>
    <section class="Blackmail">
    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-10 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.2, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                    <p className='text-white relative text-[1.3rem] font-bold pb-2 '>James Miller Hack</p>
                        <div className="pl-2 pt-1">
                            <FaPhoenixFramework className="text-white relative text-[1.5rem] font-bold "/>
                        </div>
                </motion.div>
                <Navbar/>
                </div>
   
            <div class="Blackmail-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className="flex justify-center pt-[0%]">
                  <joshua className="flex gap-4 xl:hidden md:hidden">
                      {/* <p>Home</p>
                      <p>Service</p> */}
                  </joshua>
                </div>

                 <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='flex justify-center pt-10 pl-3 pr-1 xl:pl-0 pb-[10%]'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.7, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <p className='text-[30px] xl:text-[40px] font-semibold'>Blackmail/Extortion Recovery</p>
                  </motion.div>
               </div>
               </div>
            </div>
    </section>

    <div className="bg-[#e5e8e8] h-fit">
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                              James Miller is an advocate for combating the insidious acts of blackmail and extortion. Our mission is to 
                              extend a helping hand to individuals who find themselves ensnared in the distressing grip of these malicious 
                              practices. We understand the emotional and psychological turmoil that victims often endure when confronted 
                              with the threats and manipulation inherent in blackmail and extortion situations. Through our dedicated efforts,
                               we strive to empower and support these individuals on their path to recovery and justice.</motion.p> <br/> <br/>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                                  If you or someone you know is grappling with the harrowing effects of blackmail or extortion, we urge you to take a 
                                  step towards reclaiming your life. Reach out to us at James Miller, where our team of experts is committed to providing
                                   guidance, resources, and tailored solutions to navigate these challenges. By offering a comprehensive approach, we 
                                   aim to dismantle the power dynamics wielded by those who perpetrate blackmail and extortion. At James Miller, we 
                                   stand resolute in our commitment to fostering a safer environment and a brighter future for all those affected by 
                                   these harmful actions."<br />
                                
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://st3.depositphotos.com/29384342/33695/i/450/depositphotos_336952826-stock-photo-sad-girl-complaining-reading-bad.jpg" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bg-[#fefefe] h-fit">
           <motion.div className='flex justify-center pt-10'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <p className='text-center font-semibold text-[#1eb2a6]'>Solutions</p>
            </motion.div>

            <motion.div className='flex justify-center'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
            <p className="text-black font-bold text-[2.4rem] text-center ">
              Key Services
            </p>
            </motion.div>



            <div className="mx-auto max-w-7xl overflow-hidden">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.1, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                          <img src="broken-hear.png" alt="" className="!w-[66px] pl-3 pr-3 pt-3 pb-3"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Sextortion
                             <br/> Help</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="shakespeare.png" alt="" className="!w-[80px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Help From<br/>Online Threats</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="intimidate.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Coercion <br/>Help</p>
                           
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="extortion.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-Black font-bold text-[1.2rem]
                            pt-3">Extortion<br/> Help</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="asset.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Money Recovery<br/>From Blackmail</p>
                        </div>

                    </motion.div>


                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="shiel.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-[black] font-bold text-[1.2rem]
                            pt-3">24Hrs<br/>Support</p>
                        </div>

                    </motion.div>

                    
                </div>
            </div>
            
           </div>
           <div className="bg-[#ededef] h-fit">
            <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6]">WAYS WE HELP</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Why Choose Us</p>
                </motion.div>





            <div className="mx-auto overflow-hidden  md:max-w-7xl pb-10">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">  
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                            <img src="https://media.istockphoto.com/id/968961806/photo/it-is-deal.jpg?s=612x612&w=0&k=20&c=U9dSYB4nTW18SldQCYe_08KGL8i8sW2txvVnHtF6Ar4=" 
                            alt='' className='rounded-md'/>                        
                        </div>
                    </div>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-md right__border"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        With James Miller leading the way and our dedicated team by your side, we're your unwavering ally in breaking 
                        free from the grip of blackmail. Our unwavering commitment to excellence, coupled with our team's profound 
                        understanding of the complexities of recovery, sets us apart. Here's why choosing us is the optimal decision 
                        for you and your assets:
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Expertise in Complex Recovery:</span> With 
                                extensive expertise in navigating intricate recovery scenarios, we stand ready to 
                                guide you through the complexities of blackmail and extortion recovery. Trust our 
                                seasoned team to lead the way..
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Holistic Security Solutions:</span> Our commitment extends 
                                beyond recovery, encompassing safeguarding your future. Explore our comprehensive security 
                                measures designed to prevent future threats and protect your assets.
                            </p>
                        </motion.div>

                       

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Confidentiality and Trust:</span>  Trust is the 
                                cornerstone of our relationship. Count on us to handle your case with the utmost 
                                confidentiality and discretion, ensuring your peace of mind throughout the recovery process.
                            </p>
                        </motion.div>

                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    
                        
                  </div>
                </div>
                <div className="mx-auto overflow-hidden  md:max-w-7xl pb-[10%]" id='Review'>
                        <BlackmailReview/>
                </div>
            </div>

            <section class="hero">
                <div class="hero-content">
                  <h2 className="text-center">Need our professional services?</h2>
                  <p className="text-center">Contact us now to get started!</p>
                  <Link to="/Contact" class="contact-btn">Contact Us</Link>
                </div>
            </section>
            

    <Footer/>
    
    
    </>
    </div> )}
    </div>
  )
}

export default Blackmail;