import React from 'react';
import Navbar from "./Navbar";
import { motion } from 'framer-motion';
import { FaPhoenixFramework } from "react-icons/fa";
import CircularProgress from './hooks/CircularProgress';
import {BsCheck2All} from "react-icons/bs";
import { useRef, useEffect, useState } from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import { Link } from "react-router-dom";
import Footer from './hooks/Footer';
import Progress from "./hooks/Progress";

const About = () => {
    const [isLoading, setIsLoading] = useState(true)
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); 
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };

    useEffect(()=> {
        setIsLoading(true)
        setTimeout(()=> {
           setIsLoading(false)
  
        },5000)
   }, [])
  return (
<div>

{isLoading ? (
        
        <div className="flex justify-center h-screen bg-[#e5e8e8]">
        <Progress  />
      </div>
        
  
    
  ) : ( <div className=''>
    <>
    <section class="About">
    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-10 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.2, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                    <p className='text-white relative text-[1.3rem] font-bold pb-2 '>James Miller Hack</p>
                        <div className="pl-2 pt-1">
                            <FaPhoenixFramework className="text-white relative text-[1.5rem] font-bold "/>
                        </div>
                </motion.div>
                <Navbar/>
                </div>
   
            <div class="Crypto-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className="flex justify-center pt-[0%]">
                  <joshua className="flex gap-4 xl:hidden md:hidden">
                      {/* <p>Home</p>
                      <p>Service</p> */}
                  </joshua>
                </div>

                 <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='flex justify-center pt-10 pl-3 pr-1 xl:pl-0 pb-[10%]'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.7, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <p className='text-[30px] xl:text-[40px] font-semibold'>About Us</p>
                  </motion.div>
               </div>
               </div>
            </div>
    </section>


    <div className='bg-[#e5e8e8] h-fit'>
          <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                At James Miller Hack, we stand resolutely by your side, poised to counteract the machinations of scammers and 
                                steadfastly restore the fruits of your labor. Through the fusion of our specialized expertise and avant-garde 
                                technological prowess, we present you an unparalleled tapestry of tranquility by reclaiming financial resources 
                                ensnared within the clutches of fraudulent schemes. Our coterie of seasoned luminaries comprehends the profound 
                                ramifications of succumbing to scams, thus embarking upon each case with the precision of a master craftsman. 
                                Impeccable in our discretion, we weave bespoke solutions attuned to the unique cadence of your circumstances. 
                                Whether ensnared in the web of online phishing, ensnared by investment chicanery, or beguiled by the allure of 
                                romance turned treacherous, James Miller Hack stands primed to tackle even the most labyrinthine of entanglements.</motion.p> <br/> <br/>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                Endowed with a lineage of triumphant retrievals and an extensive tapestry of globally esteemed patrons, 
                                we've solidified our stature as an unimpeachable bastion of recovery. As we ardently labor to resurrect 
                                your assets, we concurrently dispatch an unequivocal message to perpetrators of deceit: their stratagems 
                                shall be met with swift and unerring retribution. Place your unwavering faith in James Miller Hack to 
                                redefine the landscape of romance scam recovery and set ablaze the path toward financial absolution.
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://www.dekhnews.com/wp-content/uploads/2020/08/In-Conclusion.jpg" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
                </div>
              
              <div className="mx-auto overflow-hidden  md:max-w-7xl">
                  <div className='grid ml-3 mr-2 grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-5 pb-10 mt-[5%]'>
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                          <CircularProgress/>
                         
                        </div>

                        <div className="flex justify-center">
                        <p className="font-bold text-black uppercase text-center">
                            Refund Policy
                          </p>
                        </div>
                    </div>
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                          <CircularProgress/>
                        </div>

                        <div className="flex justify-center">
                        <p className="font-bold text-black uppercase">
                            Service
                          </p>
                        </div>
                    </div>
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                          <CircularProgress/>
                        </div>

                        <div className="flex justify-center">
                        <p className="font-bold text-black uppercase">
                            Experience
                          </p>
                        </div>
                    </div>
                </div>
              </div>
          </div>
          <div className="bg-[white] h-fit">
           <motion.div className='flex justify-center pt-10'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <p className='text-center font-semibold text-[#1eb2a6]'>VALUES</p>
            </motion.div>

            <motion.div className='flex justify-center'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
            <p className="text-black font-bold text-[2.4rem] text-center ">
              Our Core Value
            </p>
            </motion.div>



            <div className="mx-auto max-w-7xl overflow-hidden">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                    <div className="bg-green h-fit">
                        <div className="flex justify-center">
                        <img src="https://img.freepik.com/premium-photo/confident-happy-relaxed-business-woman-looking-professional-ready-work-her-desk-office-young-female-sitting-her-table-smiling-feeling-positive-about-her-career_590464-67649.jpg?w=2000" 
                        alt="" className="border__img rounded-xl"/>
                        </div>
                    </div>

                    <div className="bg-green-00 h-fit">
                        <motion.div className="flex"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className="pt-1 pr-5 pl-3">
                                <BsCheck2All className="text-[1.4rem] text-[#1eb2a6]"/>
                            </div>
                            <div>
                                <p className=" text-black"><span className="font-semibold text-black">Trust Building : </span>
                                Integrity is the foundation upon which trust is built. People are more likely to trust individuals and 
                                organizations that consistently demonstrate honesty and transparency in their actions.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div className="flex mt-5"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className="pt-1 pr-5 pl-3">
                                <BsCheck2All className="text-[1.4rem] text-[#1eb2a6]"/>
                            </div>
                            <div>
                                <p className=" text-[black]"><span className="font-semibold text-black">Strong Relationships: </span>
                                Integrity fosters strong and meaningful relationships. People are drawn to those who exhibit sincerity, 
                                as it creates a sense of authenticity and genuineness.</p>
                            </div>
                        </motion.div>

                        <motion.div className="flex mt-5"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className="pt-1 pr-5 pl-3">
                                <BsCheck2All className="text-[1.4rem] text-[#1eb2a6]"/>
                            </div>
                            <div>
                                <p className=" text-[black]"><span className="font-semibold text-black">Long-Term Success: </span>
                                Businesses and individuals built on a foundation of integrity tend to enjoy long-term success. 
                                Sustainable success is often a result of ethical practices and consistent honesty.
                               </p>
                            </div>
                        </motion.div>

                        <motion.div className="flex mt-5"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.8, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className="pt-1 pr-5 pl-3">
                                <BsCheck2All className="text-[1.4rem] text-[#1eb2a6]"/>
                            </div>
                            <div>
                                <p className=" text-[black]"><span className="font-semibold text-black">Credibility: </span>
                                Maintaining integrity enhances credibility. When you uphold your commitments and stay true to your values, 
                                others perceive you as reliable and credible.</p>
                            </div>
                        </motion.div>
                        <div className="pt-3 pr-5 pl-3">
                              <p>
                              We embrace honesty, transparency, and ethical conduct as the bedrock of trust and enduring relationships. 
                              Our decisions reflect these values, serving the interests of clients, partners, and stakeholders. Upholding 
                              the highest integrity standards, we ensure each action mirrors our unwavering commitment to doing right, 
                              regardless of observation.
                              </p>
                            </div>
                    </div>
                </div>

                <div className=" mx-auto overflow-hidden md:max-w-6xl">
                     <div className="grid  grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-0 pb-10 mt-[2%]">
                            <div className="bg-[reen] h-fit flex justify-center overflow-hidden">
                            <div className=''>
                                <p className='text-[1.5rem] xl:text-[2rem] font-bold text-center'>
                                    <AnimatedNumbers value={10000}/><span className="text-[#1eb2a6]">+</span>
                                </p>
                                <p className='text-center text-[1.2rem] font-bold text-[#1eb2a6]'>
                                   Trusted <br/> Clients
                                </p>
                          </div>
                            </div>

                            <div className="bg-[reen] h-fit flex justify-center overflow-hidden">
                            <div>
                                <p className='text-[1.5rem] xl:text-[2rem] font-bold text-center'>
                                    $<AnimatedNumbers value={46}/><span className='text-[#1eb2a6]'>m</span>
                                </p>
                                <p className='text-center text-[1.2rem] font-bold text-[#1eb2a6]'>
                                    Money <br/> Returned
                                </p>
                          </div>
                            </div>
                            <div className="bg-[reen] h-fit flex justify-center overflow-hidden">
                            <div>
                                <p className='text-[1.5rem] xl:text-[2rem] font-bold text-center'>
                                    <AnimatedNumbers value={12}/><span className="text-[#1eb2a6]">+</span>
                                </p>
                                <p className='text-center text-[1.2rem] font-bold text-[#1eb2a6]'>
                                    Years Of <br/> Experience
                                </p>
                          </div>
                            </div>
                     </div>
                </div>
            </div>
        </div>

            <section class="hero">
                <div class="hero-content">
                  <h2 className="text-center">Need our professional services?</h2>
                  <p className="text-center">Contact us now to get started!</p>
                  <Link to="/Contact" class="contact-btn">Contact Us</Link>
                </div>
            </section>
            
        <Footer/>
    </>
    </div> )}
    </div>
  )
}

export default About