import React from 'react';
import Navbar from './Navbar';
import { motion } from "framer-motion";
import { FaPhoenixFramework } from "react-icons/fa";
import CircularProgress from "./hooks/CircularProgress";
import  { BsCheck2All } from  "react-icons/bs";
import { Link } from "react-router-dom";
import Footer from "./hooks/Footer";
import { useState, useEffect } from 'react';
import Progress from "./hooks/Progress";
import RomanceReview from "./reviews/RomanceReview";

const RomanceScamRecovery = () => {

  const [isLoading, setIsLoading] = useState(true)


  useEffect(()=> {
      setIsLoading(true)
      setTimeout(()=> {
         setIsLoading(false)

      },5000)
 }, [])
  return (
    <div>

{isLoading ? (
        
        <div className="flex justify-center h-screen bg-[#e5e8e8]">
        <Progress  />
      </div>
        
  
    
  ) : ( <div className=''>
    <>
    <section class="Romance">
    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-10 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.2, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                    <p className='text-white relative text-[1.3rem] font-bold pb-2 '>James Miller Hack</p>
                        <div className="pl-2 pt-1">
                            <FaPhoenixFramework className="text-white relative text-[1.5rem] font-bold "/>
                        </div>
                </motion.div>
                <Navbar/>
                </div>
   
            <div class="Romance-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className="flex justify-center pt-[0%]">
                  <joshua className="flex gap-4 xl:hidden md:hidden">
                      {/* <p>Home</p>
                      <p>Service</p> */}
                  </joshua>
                </div>

                 <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='flex justify-center pt-10 pl-3 pr-1 xl:pl-0 pb-[10%]'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.7, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <p className='text-[30px] xl:text-[40px] font-semibold'>Romance Scam Recovery</p>
                  </motion.div>
               </div>
               </div>
            </div>
    </section>

    <div className="bg-[#e5e8e8] h-fit">
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                             Amidst the intricate maze of dating scams, where stories of affection cloak vanished fortunes, assets, and 
                             unlawfully seized gains, the pursuit of justice stands paramount. Within this cunning terrain, we emerge as a 
                             beacon of optimism, resolute in our mission to regain what's been wrongfully acquired. Our expertise lies in 
                             liberating funds trapped by the clutches of deceitful plots. From monetary restitution to property reclamation 
                             and salvaging stolen loans, we navigate these perilous waters with finesse. Additionally, we possess a distinct 
                             prowess in recovering funds ensnared within the intricate networks of fabricated romance accounts, ensuring the 
                             victory of truth over deception.</motion.p> <br/> <br/>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                                 Place your confidence in us for reparations. Equipped with steadfast determination, we deploy a diverse array o
                                 f strategies and assets, committed to retrieving what rightfully belongs to you. Together, we will untangle the 
                                 elaborate webs of deceit, restoring not only your financial stability but also your faith in humanity.<br />
                                
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://img.freepik.com/premium-photo/young-woman-is-holding-broken-heart-isolated-background_488220-16557.jpg" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="bg-[#fefefe] h-fit">
           <motion.div className='flex justify-center pt-10'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <p className='text-center font-semibold text-[#1eb2a6]'>Solutions</p>
            </motion.div>

            <motion.div className='flex justify-center'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
            <p className="text-black font-bold text-[2.4rem] text-center ">
              Key Services
            </p>
            </motion.div>



            <div className="mx-auto max-w-7xl overflow-hidden">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.1, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                          <img src="soldier.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Fake Military 
                             <br/>Scam Recovery</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="Loan.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Loaned Money<br/>Recovery</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="deal.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Properties<br/>Recovery</p>
                           
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="credit-card.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-Black font-bold text-[1.2rem]
                            pt-3">Hacked Bank<br/>Account Recovery</p>
                        </div>

                    </motion.div>

                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="asset.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-black font-bold text-[1.2rem]
                            pt-3">Money Recovery From <br/>Romance Scammers</p>
                        </div>

                    </motion.div>


                    <motion.div className="bg-[reen]  fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className="flex justify-center">
                        <img src="shiel.png" alt="" className="!w-[90px] pl-3 pr-3 pt-1 pb-1"/>
                        </div>

                        <div>
                           <p className="text-center text-[black] font-bold text-[1.2rem]
                            pt-3">24Hrs<br/>Support</p>
                        </div>

                    </motion.div>

                    
                </div>
            </div>
            
           </div>
           <div className="bg-[#ededef] h-fit">
            <motion.div className="pt-10"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#1eb2a6]">WAYS WE HELP</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Why Choose Us</p>
                </motion.div>





            <div className="mx-auto overflow-hidden  md:max-w-7xl pb-10">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">  
                    <div className="bg-[reen] h-fit">
                        <div className='flex justify-center'>
                            <img src="https://media.istockphoto.com/id/968961806/photo/it-is-deal.jpg?s=612x612&w=0&k=20&c=U9dSYB4nTW18SldQCYe_08KGL8i8sW2txvVnHtF6Ar4=" 
                            alt='' className='rounded-md'/>                        
                        </div>
                    </div>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-md right__border"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        We're your trusted partner in romance scam recovery, dedicated to restoring your financial stability and faith in 
                        humanity. Our expert strategies liberate funds from deceitful traps, ensuring truth prevails. From dismantling fake 
                        romance accounts to reclaiming lost finances, our comprehensive approach empowers you to overcome romance scams with 
                        confidence.
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Emotional Support:</span> We recognize that recovering from a 
                                romance scam can be emotionally challenging. Our team offers empathetic support throughout 
                                the process, helping you rebuild your confidence and trust.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Legal Expertise:</span> With 
                                a deep understanding of legal frameworks, we leverage our legal expertise to 
                                hold scammers accountable and ensure your rights are protected.
                            </p>
                        </motion.div>

                       

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#1eb2a6]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Restoring Faith:</span>  Our 
                                ultimate goal is to restore your faith in both financial security and 
                                human connections. We believe that everyone deserves justice and a chance to rebuild after a scam.
                            </p>
                        </motion.div>

                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <CircularProgress/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    
                        
                  </div>
                </div>
                <div className="mx-auto overflow-hidden  md:max-w-7xl pb-[10%]" id='Review'>
                        <RomanceReview/>
                </div>
            </div>

            <section class="hero">
                <div class="hero-content">
                  <h2 className="text-center">Need our professional services?</h2>
                  <p className="text-center">Contact us now to get started!</p>
                  <Link to="/Contact" class="contact-btn">Contact Us</Link>
                </div>
            </section>
            

    <Footer/>
    
    
    </>
    </div> )}
    </div>
  )
}

export default RomanceScamRecovery;