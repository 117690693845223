import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link } from "react-router-dom";


export default function MediaCard() {
  return (
    <Card sx={{ maxWidth: 500 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="https://techcrunch.com/wp-content/uploads/2019/06/GettyImages-597436002-1.jpg?w=1390&crop=1"
        title="green iguana"
      />
      <CardContent>
      <p className="font-bold ">
      Car-sharing company Getaround cuts one-third of US workforce
        </p>
        <div className="pt-3">
            <p>
              Kirsten Korosec • FEB 7, 2024• <span className="text-[#1eb2a6]">News</span>
            </p>
        </div>
        <div className="pt-5">
        Getaround, a company that helps vehicle owners rent out their cars, trucks and SUVs to other peers, is cutting 30% of its North American workforce as part of a restructuring.
        The company said in a statement it will restructure its workforce and operations to reduce costs in hopes of extending its cash runway and accelerating “its path to profitability.”
        </div>
      </CardContent>
        <div className="pl-5 pb-5">
            <Link to="/Articles"><p className="text-[#1eb2a6] font-bold">
                {/* Continue Reading... */}
            </p>
            </Link>
        </div>
    </Card>
  );
}

